import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import jsPDF from 'jspdf';
import empty from './assets/no-data.svg';
import loadIcon from './assets/load2.gif';

import './App.css';

import placeholder from './assets/placeholder.png';

function App() {
  const [selectedFile, setSelectedFile] = useState();
  const [api_pos, setApi_pos] = useState(null);
  const fileInput = useRef();
  const imgUp = useRef();

  const [response, setResponse] = useState({});
  const [load, setLoad] = useState(0);

  const handleCallback = (responseData, loadData) => {
    setResponse(responseData);
    setLoad(loadData);
  };

  useEffect(() => {
    fileInput.current.addEventListener('change', function () {
      if (this.files && this.files[0]) {
        imgUp.current.onload = () => {
          URL.revokeObjectURL(imgUp.current.src);
        };

        imgUp.current.src = URL.createObjectURL(this.files[0]);
        imgUp.current.classList.remove('placeholder');
      }
    });
  }, [selectedFile]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleCallback({}, 1);

    var formData = new FormData();
    formData.append('image_file', selectedFile);

    var requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    };

    if (api_pos != null) {
      fetch(api_pos, requestOptions)
        .then((response) => response.json())
        .then((result) => handleCallback(result, 0))
        .catch((error) => console.log('error', error));
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleApi = (event) => {
    setApi_pos(encodeURI(event.target.value));
  };
  const placeholderImg = useRef();
  const saveData = () => {
    const doc = new jsPDF();
    Object.keys(response).map((key, i) => {
      doc.text(key + ': ' + response[key], 20, 10 + i * 14, {
        lineHeightFactor: 1.15,
        maxWidth: 300,
      });
      return 0;
    });
    doc.output('dataurlnewwindow', 'mrzData.pdf');
  };
  const DisplayDownload = () => {
    if (Object.keys(response).length !== 0) {
      // placeholderImg.current.style = 'display:none;';
      return (
        <div className='download-btn' onClick={saveData}>
          Download PDF
        </div>
      );
    }
    // if (load === 1) {
    //   placeholderImg.current.src = loadIcon;
    // }
  };

  return (
    <div className='App'>
      <Router>
        <Navbar />
        <div className='up-res-wrapper'>
          <div className='upload-card'>
            <div className='image-comp'>
              <form className='image-up-form' onSubmit={handleSubmit}>
                <div className='label'>
                  Instructions :
                  <br />
                  1. Input the API you want to test.
                </div>
                <img id='myImg' className='placeholder' ref={imgUp} src={placeholder} alt='' />
                <input
                  type='text'
                  placeholder='Your API...'
                  onChange={(e) => handleApi(e)}
                  required
                  style={{ border: '1px solid #808080', padding: '0 10px', height: '30px', borderRadius: '20px' }}
                />
                <div className='btn-wrapper'>
                  <label htmlFor='file-upload' className='file-upload'>
                    <input type='file' ref={fileInput} id='file-upload' name='filename' onChange={handleFileSelect} />
                    Upload Image
                  </label>
                  <input type='submit' id='submit-btn' value={'Submit Image'} />
                </div>
              </form>
            </div>
          </div>
          <div className='result-card'>
            <div className='data-comp'>
              <div className='labelData'>Result Data</div>
              <div className='response-data' style={{wordWrap:"break-word",overflowY:"scroll",border:"1px solid black",height:"240px",background:"black",color:"white",fontSize:"0.9rem",lineHeight:"2.5rem",padding:"20px"}}>
                {/* <img className='placeholder' src={empty} alt='' ref={placeholderImg}></img> */}
                {Object.keys(response).map((key, i) => (
                  <div key={i} className='resp-key-data'>
                    {key} : {response[key]}
                  </div>
                ))}
                {/* {JSON.stringify(response)} */}
              </div>
              <DisplayDownload />
            </div>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
