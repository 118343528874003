import React from 'react';
import logo from '../../assets/logo.png';

import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className='title'>
        <a href='https://docextractor.com'>
          <img src={logo} alt='logo' />
        </a>
      </div>
      <div className='options'>
        <NavLink to={'/test-URL'} id='test-URL' className={({ isActive }) => (isActive ? 'active option' : 'option')}>
          Test&nbsp;URL
        </NavLink>
      </div>
    </div>
  );
};

export default Navbar;
