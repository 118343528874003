import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='demo'>
        Like Our Demo? <a href='https://docextractor.com/contact-us/'> Contact us.</a>
      </div>
      <div className='copyright'> &#169; docextractor.com . All rights reserved.</div>
    </div>
  );
};

export default Footer;
